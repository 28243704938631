import { createTheme, ThemeProvider } from '@mui/material/styles';
import React from 'react';
import TelegramIcon from '@mui/icons-material/Telegram';
import { IconButton, Link } from '@mui/material';

const theme = createTheme({
  breakpoints: {
    values: {
      xs: 0,   // для мобільних
      sm: 555, // для планшетів
      md: 950, // для стандартних ноутбуків
      lg: 1200, // для великих моніторів
      xl: 1355, // для дуже великих екранів
      xxl: 1800, // кастомний брейкпоінт
    },
  },
});

const Icons = () => {
  return (
    <ThemeProvider theme={theme}>
      <div>
        <Link href="https://t.me/ineedmorelove">
          <IconButton sx={{
            color: "#d7d7d7",
            marginBottom: '10px',
            paddingTop: '15px',
          }}>
            <TelegramIcon 
              sx={{ 
                fontSize: {
                  xs: 100,    // Для дуже малих екранів
                  sm: 70,    // Для малих екранів
                  md: 40,    // Для середніх екранів
                  lg: 40,    // Для великих екранів
                  xl: 31,    // Для дуже великих екранів
                  xxl: 31,   // Для екстремально великих екранів
                },
              }}  
            />
          </IconButton>
        </Link>
      </div>
    </ThemeProvider>
  );
};

export default Icons;
