import React, { useEffect } from "react";
import Icons from './IconsTest';
// import '../styles/styles_portfolio.scss';

function importAll(r) {
    let images = {};
    r.keys().map((item, index) => { images[item.replace('./', '')] = r(item); });
    return images;
  }
  
  const images = importAll(require.context('./images/', false, /\.(png|jpe?g|gif)$/));

function PortfolioItem({ item }) {
  return (
    <div className={`item ${item.type}`} id={item.id}>
      {item.image && <img src={item.image} alt={item.type} className="item-image" />}
      {item.date && <p className="item-date">Created on: {item.date}</p>}
    </div>
  );
}

function Row({ items }) {
  return (
    <div className="row">
      {items.map((item) => (
        <PortfolioItem key={item.id} item={item} />
      ))}
    </div>
  );
}


const rows2025 = [
  [
    { id: "56", type: "cover", image: images['cover_kameni_2025.gif'], date: "2025" },
  ],

];

const rows2024 = [
  [
    { id: "45", type: "photo_wide1", image: images['photo_wide10.jpg'], date: "2024" },
    { id: "46", type: "video", image: images['nezrozumila_2024.gif'], date: "2024" },
    
  ],
  [
    { id: "42", type: "video", image: images['coals_2024.gif'], date: "2024" },
    { id: "43", type: "photo_wide3", image: images['photo_wide11.jpg'], date: "2024" },
    { id: "44", type: "cover", image: images['cover_nezrozumila_2024.jpg'], date: "2024" },
    
  ],
    [
      { id: "41", type: "photo_wide1", image: images['photo_wide9.jpg'], date: "2024" },
      { id: "40", type: "video", image: images['smv_2024.gif'], date: "2024" },
    ],
    [
      { id: "50", type: "video", image: images['sadsvit_2024.gif'], date: "2024" },    
      { id: "49", type: "photo_wide1", image: images['photo_wide12.jpg'], date: "2024" },  
    ],
    [
      { id: "48", type: "video", image: images['photo_wide13.jpg'], date: "2024" },
      { id: "47", type: "video", image: images['vladick1_2024.gif'], date: "2024" },
      { id: "51", type: "video", image: images['vladick2_2024.gif'], date: "2024" },   
    ],
    [
      { id: "1", type: "video", image: images['nenagaduye_2024.gif'], date: "2024" },
      { id: "2", type: "photo_wide3", image: images['photo_wide8.jpg'], date: "2024" },
      { id: "39", type: "cover", image: images['cover_sutinki_2024.gif'], date: "2024" },
    ],
    [
        { id: "35", type: "photo_wide2", image: images['photo_wide7.jpg'], date: "2024" },
        { id: "36", type: "cover", image: images['cover_yadestam_2024.gif'], date: "2024" },
        { id: "37", type: "cover", image: images['cover_4dworfa_2024.jpg'], date: "2024" },
        { id: "38", type: "cover", image: images['cover_euphoria_2024.jpg'], date: "2024" },
      ],
    [
        { id: "31", type: "cover", image: images['cover_foreveryoung_2024.jpg'], date: "2024" },
        { id: "32", type: "cover", image: images['cover_rockmebaby_2024.jpg'], date: "2024" },
        { id: "33", type: "photo_wide2", image: images['photo_wide6.jpg'], date: "2024" },
        { id: "34", type: "cover", image: images['cover_dach_2024.jpg'], date: "2024" },
      ],
    [
      { id: "3", type: "cover", image: images['cover_kvartaly_2024.jpg'], date: "2024" },
      { id: "4", type: "cover", image: images['cover_guby_2024.jpg'], date: "2024" },
      { id: "5", type: "photo_wide4", image: images['photo_wide5.jpg'], date: "2024" },
      { id: "6", type: "video", image: images['4dworfa_2024.gif'], date: "2024" },
    ],
    [
      { id: "7", type: "photo_wide3", image: images['photo_wide4.jpg'], date: "2024" },
      { id: "8", type: "video", image: images['vydyhai_2024.gif'], date: "2024" },
      { id: "9", type: "cover", image: images['cover_vydyhai_2024.jpg'], date: "2024" },
    ],
    [
      { id: "10", type: "video", image: images['dotyky_2024.gif'], date: "2024" },
      { id: "11", type: "cover", image: images['cover_scandal_2024.jpg'], date: "2024" },
      { id: "12", type: "cover", image: images['cover_givemedrugs_2024.jpg'], date: "2024" },
      { id: "13", type: "cover", image: images['cover_latex_2024.jpg'], date: "2024" },
    ],
  ];


const rows2023 = [
    [
      { id: "14", type: "photo_wide1", image: images['photo_wide3.jpg'], date: "2023" },
      { id: "15", type: "video", image: images['szkoda_2023.gif'], date: "2023" },
    ],
    [
      { id: "53", type: "video", image: images['clonnex_2023.gif'], date: "2023" },
      { id: "52", type: "photo_wide1", image: images['photo_wide15.jpg'], date: "2023" },
    ],
    [
      { id: "54", type: "photo_wide1", image: images['photo_wide14.jpg'], date: "2023" },
      { id: "55", type: "video", image: images['vistrily_2023.gif'], date: "2023" },
    ],
    [
      { id: "16", type: "video", image: images['jazz_2023.gif'], date: "2023" },
      { id: "17", type: "cover", image: images['cover_szkoda_2023.jpg'], date: "2023" },
      { id: "18", type: "cover", image: images['cover_kurym_2023.jpg'], date: "2023" },
      { id: "19", type: "photo_wide4", image: images['photo_wide2.jpg'], date: "2023" },
    ],
    [
      { id: "20", type: "cover", image: images['cover_misacgarny_2023.jpg'], date: "2023" },
      { id: "21", type: "photo_wide3", image: images['photo_wide1.jpg'], date: "2023" },
      { id: "22", type: "video", image: images['apathy_konc_2023.gif'], date: "2023" },
    ],
    [
      { id: "23", type: "cover", image: images['cover_stankons_2023.jpg'], date: "2023" },
      { id: "24", type: "cover", image: images['cover_fermomony_2023.jpg'], date: "2023" },
      { id: "25", type: "cover", image: images['cover_jiva_2023.jpg'], date: "2023" },
      { id: "26", type: "cover", image: images['cover_jazz_2023.jpg'], date: "2023" },
      { id: "30", type: "cover", image: images['cover_diana_2023.jpg'], date: "2023" },
    ],
  ];

  const rows2022 = [
    [
      { id: "27", type: "video", image: images['cornwave_2022.gif'], date: "2022" },
      { id: "28", type: "video", image: images['rihter_2022.gif'], date: "2022" },
      { id: "29", type: "video", image: images['plato_2022.gif'], date: "2022" },
    ],
  ];


function Portfolio({ data }) {
  return (
    <div className="portfolio">
      <div className="header">
        <div className="text1">
          <h2 className="text_header">[YEAR]</h2>
          <h2 className="text_header">[VIDEOS]</h2>
          <h2 className="text_header">[COVERS/PHOTOS]</h2>
        </div>
        <div className="text2">
          <h2 className="text_header">[CONTACT ME]</h2>
        </div>
      </div>
      {data.map(({ year, rows }) => (
        <div className="card" key={year}>
          <h2 className="text_year">[{year}]</h2>
          <div className="body2">
            {rows.map((row, index) => (
              <Row key={index} items={row} />
            ))}
          </div>
        </div>
      ))}
      <footer>
        <p className="footer_p"> <Icons />
          Наше творче об'єднання характеризує себе як клуб любителів готочок та альтушок.
          <br />
          Вайб і посил музики направлений на аудиторію, яка переживає з нами одні і ті самі емоції та переживання.
          <br />
          Ми любимо всіх колишніх, тому інколи любимо писати лишнє, є певні риси які притаманні таким
          людям як ми: 0 сексу, 0 лайків у тіндері, 2800 птс у доті.
          <br />
          Ми лише починаємо, тому шукаємо однодумців
          у світі музики які хотітимуть просувати цю ідею з нами. Ніколи не здавайтесь.
          <br /> <br />
          #ШкільнаМеланхолія #БезНегативу #МедіаПрокачка
          <br /> <br />
          [© Шкільна Меланхолія]
        </p>
        <p className="footer_p_phones"> <Icons />
          Наше творче об'єднання характеризує себе як клуб любителів готочок та альтушок.
          Вайб і посил музики направлений на аудиторію, яка проходить з нами одні й ті самі емоції та переживання.
          Ми любимо всіх колишніх, тому інколи любимо писати лишнє, є певні риси які притаманні таким
          людям як ми: 0 сексу, 0 лайків у тіндері, 2800 птс у доті.
          Ми лише починаємо, тому шукаємо однодумців у світі музики які хотітимуть просувати
          цю ідею з нами. Ніколи не здавайтесь.
          <br /> <br />
          #ШкільнаМеланхолія #БезНегативу #МедіаПрокачка
          <br /> <br />
          [© Шкільна Меланхолія]
        </p>
      </footer>
    </div>
  );
}

// Передаємо масиви для різних років
export default function App() {
  
  useEffect(() => {
    // Динамічно імпортуємо стилі для сторінки Portfolio
    import('../styles/styles_portfolio.scss');

    return () => {
      // Очистка стилів або виконання інших операцій
    };
  }, []); // Тільки при завантаженні цього компонента

  const portfolioData = [
    { year: 2025, rows: rows2025 },
    { year: 2024, rows: rows2024 },
    { year: 2023, rows: rows2023 },
    { year: 2022, rows: rows2022 },
  ];

  return <Portfolio data={portfolioData} />;
}
