import React, { useEffect } from 'react';
import Person from './Person';
import { IconButton } from '@mui/material';
import TelegramIcon from '@mui/icons-material/Telegram';
import Icons from './IconsTest';
// import '../styles/styles_links.scss';

function importAll(r) {
  let images = {};
  r.keys().map((item, index) => { images[item.replace('./', '')] = r(item); });
  return images;
}

const images = importAll(require.context('./images/', false, /\.(png|jpe?g|svg)$/));

function Persons() {
  const persons = [
    {
      id: 1,
      name: '[CRUSHGLAD]',
      imageButtons: [
        { text: '[IG: CRUSHGLAD]', link: 'https://www.instagram.com/crushglad/', image: images['crushglad_img.jpg'] }
      ],
      buttons: [
        { text: 'Не зрозуміла', link: 'https://fanlink.tv/ne_zrozumila', image: images['ne-zrozumila_cover.jpg'] },
        { text: 'SoundCloud', link: 'https://soundcloud.com/crushglad' }
      ]
    },
    {
      id: 2,
      name: '[ПЛАСТІВЦІ З МОЛОКОМ]',
      imageButtons: [
        { text: '[IG: H3.11IH4N]', link: 'https://www.instagram.com/h3.11ih4n/', image: images['pzm_img.jpg'] }
      ],
      buttons: [
        { text: 'Що з нами стає?', link: 'https://ditto.fm/shho-z-nami-staje', image: images['shoznamy.jpg'] },
        { text: 'SoundCloud', link: 'https://soundcloud.com/h3ll1h4n' }
      ]
    },
    {
      id: 3,
      name: '[ТАЗАШО]',
      imageButtons: [
        { text: '[IG: TAZZASHO]', link: 'https://www.instagram.com/tazzasho/', image: images['tazasho_img.jpg'] }
      ],
      buttons: [
        { text: '4 Дворфа', link: 'https://ditto.fm/4-dvorfa', image: images['4dwarfs_cover.jpg'] },
        { text: 'SoundCloud', link: 'https://soundcloud.com/tazzasho' }
      ]
    },
    {
      id: 4,
      name: '[SKAMEYKAMOLOKO]',
      imageButtons: [
        { text: '[IG: SKAMEYKAMOLOKO]', link: 'https://www.instagram.com/skameykamoloko/', image: images['vova_img.jpg'] }
      ],
      buttons: [
        { text: 'Багато травм', link: 'https://ditto.fm/bagato-travm', image: images['bahato-travm_cover.jpg'] },
        { text: 'SoundCloud', link: 'https://soundcloud.com/skameykamoloka' }
      ]
    },
    {
      id: 5,
      name: '[ІНШІ ПОСИЛАННЯ]',
      imageButtons: [
        { text: '[YOUTUBE: ШКІЛЬНА МЕЛАНХОЛІЯ]', link: 'https://www.youtube.com/@ineedmorelove.', image: images['youtubelogo_img.jpg'] }
      ],
      buttons: []
    }
  ];

  useEffect(() => {
    // Динамічно імпортуємо стилі для цієї сторінки
      import('../styles/styles_links.scss');
        
      // Очистка стилів, коли компоненти буде видалено (якщо потрібно)
      return () => {
          // Можна видалити стилі або виконати інші операції очищення
      };
  }, []);  // Зробити це лише при завантаженні компонента

  
  // Target blank 
  useEffect(() => {
    function setLinkBehavior() {
      const links = document.querySelectorAll('a');
      if (window.innerWidth >= 854) {
        links.forEach(link => {
          link.setAttribute('target', '_blank');
        });
      } else {
        links.forEach(link => {
          link.removeAttribute('target');
        });
      }
    }

    // Викликаємо функцію при завантаженні сторінки та при зміні розміру вікна
    window.addEventListener('resize', setLinkBehavior);
    setLinkBehavior(); // Викликаємо відразу після завантаження

    // Очищуємо ефект при розмонтуванні компонента
    return () => window.removeEventListener('resize', setLinkBehavior);


  }, []);

  return (
    <div>
      <div className='text-desc-containter'><h2 className='h2_text_desc'>[Шкільна Меланхолія] <br /> -тут можна якийсь текст смішний </h2>
        <header className='header_top'>
          <h2 className="h2_head">[fuckemogirls.com]</h2>
        </header>
      </div>
      <div className="persons">
        {persons.map(person => (
          <Person key={person.id} person={person} />
        ))}
      </div>
      <footer>
        <p className="footer_p"> <Icons />
          Наше творче об'єднання характеризує себе як клуб любителів готочок та альтушок.
          <br />
          Вайб і посил музики направлений на аудиторію, яка переживає з нами одні і ті самі емоції та переживання.
          <br />
          Ми любимо всіх колишніх, тому інколи любимо писати лишнє, є певні риси які притаманні таким
          людям як ми: 0 сексу, 0 лайків у тіндері, 2800 птс у доті.
          <br />
          Ми лише починаємо, тому шукаємо однодумців
          у світі музики які хотітимуть просувати цю ідею з нами. Ніколи не здавайтесь.
          <br /> <br />
          #ШкільнаМеланхолія #БезНегативу #МедіаПрокачка
          <br /> <br />
          [© Шкільна Меланхолія]
        </p>

        <p className="footer_p_phones"> <Icons />
          Наше творче об'єднання характеризує себе як клуб любителів готочок та альтушок.
          Вайб і посил музики направлений на аудиторію, яка проходить з нами одні й ті самі емоції та переживання.
          Ми любимо всіх колишніх, тому інколи любимо писати лишнє, є певні риси які притаманні таким
          людям як ми: 0 сексу, 0 лайків у тіндері, 2800 птс у доті.
          Ми лише починаємо, тому шукаємо однодумців у світі музики які хотітимуть просувати
          цю ідею з нами. Ніколи не здавайтесь.
          <br /> <br />
          #ШкільнаМеланхолія #БезНегативу #МедіаПрокачка
          <br /> <br />
          [© Шкільна Меланхолія]
        </p>
      </footer>
    </div>
  );
}

export default Persons;
