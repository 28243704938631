import React from 'react';
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import Home from './Home/Home.js';
import Persons from './Links/Persons';
import Portfolio from './Portfolio/Portfolio.js';
import UpdateHtmlClass from './components/UpdateHtmlClass.js';  
import { useRef } from 'react';

// import './styles/styles_links.scss';
// import './styles/styles_html.scss';
// import './styles/styles_portfolio.scss';

function App() {
    
    return (
            <div className='preloader'>
                <div className='content'>
                    <BrowserRouter>
                        {/* <UpdateHtmlClass />  */}
                        <Routes>
                            <Route index element={<Persons />} />                        
                            <Route path='/portfolio' element={<Portfolio />} />

                            {/* <Route path='/home' element={<Home />} />
                            <Route path='/links' element={<Persons />} /> */} {/* аби не було доступу поки */}
                        </Routes>
                    </BrowserRouter>
                </div>
        </div>
    );
}

export default App;






// Другий варік як зробити лінки не працює хтмл класи, треба буде вибрати який варіант кращий

// import { createBrowserRouter, Route, createRoutesFromElements, RouterProvider, Outlet } from 'react-router-dom';
// const router = createBrowserRouter([
//     {
//         path: '/',
//         element: <Persons />, 
//     },
//     {   
//         path: '/home',
//         element: <Home />, 
//     },
//     {
//         path: '/links',
//         element: <Persons />, 
//     },
// ]);

// function App() {
//     return (
//         <div className="App">
//             <div className="content">
//                 <RouterProvider router={router} />
//                 {/* <UpdateHtmlClass /> */}
//             </div>
//         </div>
//     );
// }

// export default App;